<template>
  <div class="cardShipTo">
    <div class="cardShipTo-content">
      <div class="btnClose" @click="sendEvent()">
        <CIcon name="cil-x" height="25" />
      </div>

      <div class="cardShipTo-title">
        {{
          this.isNew
            ? $t('create_shipto')
            : this.isDelete
            ? $t('delete_shipto')
            : $t('edit_shipto')
        }}
      </div>
      <hr />

      <div v-if="notification">
        <CAlert :color="notificationType">
          {{ notification }}
        </CAlert>
      </div>

      <BlockInfo
        :title="$t('shipto') + ' ' + $t('number')"
        v-model="_shipTo.shipTo_number"
        :disabled="this.isDelete"
      />
        <BlockInfo
        :title="$t('shipto') + ' ' + $t('name')"
        v-model="_shipTo.shipTo_name"
        :disabled="this.isDelete"
      />
      <BlockInfo
        :title="$t('email')"
        v-model="_shipTo.email"
        :selected="_shipTo.email"
        :disabled="this.isDelete"
      />
      <BlockInfo
        :title="$t('phone_number')"
        v-model="_shipTo.phone_number"
        :selected="_shipTo.phone_number"
        :disabled="this.isDelete"
      />
      <BlockInfoSelect
        :title="$t('send_dn')"
        v-model="_shipTo.send_dn"
        :selected="_shipTo.send_dn"
        :values="[true, false]"
        :disabled="this.isDelete"
      />
      <BlockInfoSelect
        :title="$t('country')"
        v-model="_shipTo.countryCode"
        :selected="_shipTo.countryCode || countryCodes[0]"
        :values="countryCodes"
        :disabled="this.isDelete"
      />
      <BlockInfo
        :title="$t('pod_code')"
        v-model="_shipTo.pod_code"
        :disabled="this.isDelete"
      />
      <BlockInfoDatePickerShipTo
        :title="$t('expiration_date')"
        v-model="_shipTo.expiration_date"
        :disabled="this.isDelete"
      />

      <div class="cardShipTo-sectionButtons">
        <CButton
          color="white"
          size="lg"
          class="cardShipTo-button"
          @click="sendEvent()"
          >{{$t('cancel')}}</CButton
        >
        <CButton
          :color="this.isDelete ? 'danger' : 'success'"
          size="lg"
          class="cardShipTo-button"
          @click="actionEvent()"
          >{{
            this.isNew ? "Create" : this.isDelete ? this.$t('delete') : this.$t('save')
          }}</CButton
        >
      </div>
    </div>
    <div class="cardShipToShadow" @click="sendEvent()"></div>
  </div>
</template>

<script>
import BlockInfo from "./BlockInfo";
import BlockInfoDatePickerShipTo from "./BlockInfoDatePickerShipTo";
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
const moment = require("moment");

export default {
  name: "cardShipTo",
  components: {
    BlockInfo,
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
    DatePicker,
    BlockInfoDatePickerShipTo,
  },
  props: {
    shipTo: Object,
    countryCodes: Array,
    isNew: Boolean,
    isDelete: Boolean,
    notification: String,
    notificationType: String,
  },

  computed: {
    _shipTo() {
      return this.shipTo;
    },
  },
  data() {
    return {};
  },
  created() {
    const self = this;
  },
  methods: {
    sendEvent() {
      (this.classHidden = "hidden"), this.$emit("close-card", false);
    },

    actionEvent() {
      const self = this;
      if (self.isDelete) {
        self.$emit("delete-shipTo", self._shipTo);
      } else if (self.formValidate()) {
        if (self.isNew) {
          self.$emit("new-shipTo", self._shipTo);
        } else {
          self.$emit("edit-shipTo", self._shipTo);
        }
      }
    },

    formValidate() {
      const self = this;
      if (!self._shipTo.shipTo_number) {
        self.$emit("card-shipTo-error", "ShipTo number is required");
        return false;
      }
      if (!self._shipTo.shipTo_name) {
        self.$emit("card-shipTo-error", "ShipTo name is required");
        return false;
      }
      if (!self._shipTo.email) {
        self.$emit("card-shipTo-error", "Email is required");
        return false;
      }
      if (!self._shipTo.phone_number) {
        self.$emit("card-shipTo-error", "Phone number is required");
        return false;
      }
      if (!self._shipTo.send_dn && self._shipTo.send_dn != false) {
        self.$emit("card-shipTo-error", "Send delivery number is required");
        return false;
      }
      if (!self._shipTo.countryCode) {
        self.$emit("card-shipTo-error", "Country is required");
        return false;
      }
      if (!self._shipTo.expiration_date && self._shipTo.pod_code) {
        self.$emit("card-shipTo-error", "Expiration date is required");
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.cardShipToShadow {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10100;
}
.cardShipTo {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}
.cardShipTo-content {
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  max-width: 700px;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  padding-top: 53.78px;
  padding-left: 3em;
  padding-right: 3em;

  overflow-y: auto;
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5em;
  cursor: pointer;
}

.cardShipTo-title {
  font-size: 20px;
  margin-bottom: 1em;
}
.cardShipTo-subtitle {
  width: 100%;
  font-size: 16px;
  /* margin-top: 1em;
  margin-bottom: 1em; */
  padding: 1em;
  line-height: 1.3;
}
.btn-plus {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn-plus:hover {
  background: rgba(0, 0, 0, 0.05);
}

.cardShipTo-inputGroup {
  width: 25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.cardShipTo-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardShipTo-button {
  margin-left: 1em;
  font-size: 14px;
}
</style>