<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <CRow>
      <CCol>
        <ListShipTo
          v-if="!loading"
          v-bind:countryChossed="countryChossed"
          v-bind:tableItems="listShipTo"
          v-bind:countryCodes="countryCodes"
          @shipTo-countries="changeCountry"
          @shipTo-edit="showCardShipToEdit"
          @shipTo-delete="showCardShipToDelete"
          @shipTo-new="showCardShipToNew"
          @shipTo-sendSMS="showCardShipToSendSMS"
        />
      </CCol>
    </CRow>
    <transition name="slide-fade">
      <CardShipTo
        v-if="showShipToCard"
        v-bind:shipTo="shipToSelected"
        v-bind:countryCodes="countryCodes"
        v-bind:isNew="isNew"
        v-bind:isDelete="isDelete"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
        @close-card="closeCardShipTo"
        @new-shipTo="newShipTo"
        @edit-shipTo="editShipTo"
        @delete-shipTo="deleteShipTo"
        @card-shipTo-error="showToastError"
      />
    </transition>
    <transition name="slide-fade">
      <CardShipToSendSMS
        v-if="showShipToSendSMSCard"
        v-bind:shipTo="shipToSelected"
        v-bind:countryCodes="countryCodes"
        v-bind:disabled_SMS_btn="disabled_SMS_btn"
        v-bind:disabled_Email_btn="disabled_Email_btn"
        v-bind:send_Email_btn="send_Email_btn"
        v-bind:send_SMS_btn="send_SMS_btn"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
        @close-card="closeCardShipTo"
        @sendSMS-shipTo="sendSMSShipTo"
        @sendEmail-shipTo="sendEmailShipTo"
        @card-shipTo-error="showToastError"
      />
    </transition>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import ListShipTo from "../components/ListShipTo.vue";
import CardShipTo from "../components/CardShipTo.vue";
import CardShipToSendSMS from "../components/CardShipToSendSMS.vue";

export default {
  name: "ShipTos",
  components: {
    ListShipTo,
    CardShipTo,
    CardShipToSendSMS,
  },
  data() {
    return {
      loading: true,
      send_Email_btn: this.$t("send_email"),
      send_SMS_btn: this.$t("send_sms"),
      disabled_SMS_btn: false,
      disabled_Email_btn: false,
      countryChossed: "",
      listShipTo: [],
      countryCodes: [],
      country: null,
      isNew: false,
      isDelete: false,
      notification: null,
      notificationType: null,

      shipToSelected: null,
      showShipToCard: false,
      showShipToSendSMSCard: false,

      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",
    };
  },
  async created() {
    // await this.loadData();
    await this.loadCountryData();
  },
  methods: {
    async loadCountryData() {
      var self = this;
      self.country = localStorage.getItem("userCountry")
        ? JSON.parse(localStorage.getItem("userCountry"))
        : "";
      try {
        self.countryCodes = [self.country];
        const res = await self.$http.get("apiConsole/console/getCountries");
        var countries = res.data.countries;
        self.countryCodes = [];
        countries.map((e) => {
          self.countryCodes.push(e.code);
        });
        self.changeCountry(self.countryCodes[0]);
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },
    changeCountry(country) {
      var self = this;
      self.country = country;
      self.countryChossed = country;
      this.loadShipTos();
    },
    async loadShipTos() {
      var self = this;
      self.loading = true;
      const res = await self.$http.get(
        "apiConsole/console/shipTo/" + self.country
      );
      self.listShipTo = res.data.shipTos;
      self.loading = false;
    },

    showCardShipToEdit(event) {
      const self = this;
      self.showShipToCard = true;
      self.shipToSelected = event.shipToSelected;
      self.shipToSelected.interval_days =
        self.shipToSelected.interval_hours / 24;
      self.countryCodes = self.countryCodes;
      self.isNew = false;
    },
    showCardShipToDelete(event) {
      const self = this;
      self.showShipToCard = true;
      self.shipToSelected = event.shipToSelected;
      self.shipToSelected.interval_days =
        self.shipToSelected.interval_hours / 24;
      self.countryCodes = self.countryCodes;
      self.isNew = false;
      self.isDelete = true;
      self.notificationType = "danger";
      self.notification = this.$t("delete_shipto_warning") + " " + self.shipToSelected.shipTo_number
    },
    showCardShipToSendSMS(event) {
      const self = this;
      self.showShipToSendSMSCard = true;
      self.shipToSelected = event.shipToSelected;
      self.shipToSelected.interval_days =
        self.shipToSelected.interval_hours / 24;
      self.countryCodes = self.countryCodes;
      self.notificationType = "warning";
      self.notification =this.$t("send_pod_code_warning") + " " + (self.shipToSelected.pod_code || "");
      self.send_SMS_btn = this.$t("send_sms");
      self.send_Email_btn = this.$t("send_email");

    },
    showCardShipToNew() {
      const self = this;
      self.showShipToCard = true;
      self.shipToSelected = {};
      self.countryCodes = self.countryCodes;
      self.isNew = true;
    },
    closeCardShipTo() {
      const self = this;
      self.showShipToCard = false;
      self.showShipToSendSMSCard = false;
      self.isNew = false;
      self.isDelete = false;
      self.notification = null;
      self.notificationType = null;
self.disabled_SMS_btn = false;
self.disabled_Email_btn = false;
      self.shipToSelected = null;

      this.loadCountryData();
    },
    async newShipTo(newShipTo) {
      const self = this;
      try {
        newShipTo.interval_hours = newShipTo.interval_days * 24;
        const res = await self.$http.post("apiConsole/console/shipTo", {
          shipTo: newShipTo,
        });
        self.loadCountryData();
        self.closeCardShipTo();
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async editShipTo(editShipTo) {
      const self = this;
      try {
        editShipTo.interval_hours = editShipTo.interval_days * 24;
        const res = await self.$http.put("apiConsole/console/shipTo", {
          shipTo: editShipTo,
        });
        self.loadCountryData();
        self.closeCardShipTo();
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async deleteShipTo(deleteShipTo) {
      const self = this;
      try {
        deleteShipTo.interval_hours = deleteShipTo.interval_days * 24;
        const res = await self.$http.delete("apiConsole/console/shipTo", {
          data: { shipTo: deleteShipTo },
        });
        self.loadCountryData();
        self.closeCardShipTo();
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async sendSMSShipTo(sendSMSShipTo) {
      const self = this;
      try {
        self.disabled_SMS_btn = true;
        self.send_SMS_btn = "Sending SMS...";

        sendSMSShipTo.interval_hours = sendSMSShipTo.interval_days * 24;
        const res = await self.$http.post("apiConsole/console/shipTo/sendSMS", {
          shipTo: sendSMSShipTo,
        });
      } catch (e) {
        if (e.response) {
          self.send_SMS_btn = this.$t("send_sms");
          self.disabled_SMS_btn = false;
          const res = e.response;
          console.log(res);
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async sendEmailShipTo(sendEmailShipTo) {
      const self = this;
      try {
        self.disabled_Email_btn = true;
        self.send_Email_btn = this.$t("sending_email");
        sendEmailShipTo.interval_hours = sendEmailShipTo.interval_days * 24;
        const res = await self.$http.post(
          "apiConsole/console/shipTo/sendEmail",
          {
            shipTo: sendEmailShipTo,
          }
        );
      } catch (e) {
        if (e.response) {
          self.send_Email_btn = this.$t("send_email");
          self.disabled_Email_btn = false;
          const res = e.response;
          console.log(res);
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    //self.send_Email_btn = "Sending...";
    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      if (msg) {
        self.toastMessage = msg;
      } else {
        self.toastMessage = this.$t("error");
      }

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>